<template>
  <div class="container">
      <div class="point" v-for = "index in filterArray">
        <p>{{ index }}<span @click="emit('deleteItem',index)"></span></p>
        <div>{{ tags[index] }}</div>
      </div>
  </div>
</template>

<script setup>
import {ref, toRefs, watch, defineProps, defineEmits} from 'vue';

const props = defineProps({tagsIn: Object, isFilteredIn: Boolean, filterArrayIn: Object});
//const {tagsIn, isFilteredIn, filterArrayIn} = toRefs(props);

let tags = ref({});
let isFiltered = ref(false);
let filterArray = ref([]);

tags.value = props.tagsIn;
isFiltered.value = props.isFilteredIn;
filterArray.value = props.filterArrayIn;

watch(props, () => {
  if(props.tagsIn!==undefined) {
    tags.value = props.tagsIn;
    isFiltered.value = props.isFilteredIn;
    filterArray.value = props.filterArrayIn;
  }
});

const emit = defineEmits(['deleteItem']);


</script>

<style lang="scss" scoped>
.container {
  position: relative;
}
.point{
  font-size: 1rem;
  overflow: hidden;
  p{
    min-height: 28px;
    line-height: 24px;
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 28px;
    flex-wrap: wrap;
    max-width: calc(100% - 28px);
    span{
      display: inline-block;
      background-color: #fff;
      height: 28px;
      width: 24px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px;
      background-image: url(../../../public/img/delete.svg);
      margin-left: auto;
      cursor: pointer;
      position: absolute;
      right: 0px;
    }
  }
  div{
    height: 40px;
    line-height: 40px;
    background-color: #2525251A;
    border-radius: 6px;
    padding-left: 5px;
  }
}
</style>