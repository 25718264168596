<template>
  <div class="main_v8">
    <div class="data_operations">  
      <div class="controls">
        <h3>Op History</h3>
        <div class="dropdowns">
          <div class="dropdown">
            <span>Location </span> <locationDrop class="locationDropDisp" :startingid="0" :states=locationStates :enabled=locationDropDownEnabled @selection="locationSelected"></locationDrop>
          </div>
          <div class="dropdown">
            <span>Vehicle </span> <vehicleDrop class="vehicleDropDisp" :startingid="0" :states=vehicleStates :enabled=vehicleDropDownEnabled @selection="vehicleSelected"></vehicleDrop>
          </div>
         </div>
        <div v-if="currentDropletId!=''" class="controls_right">
          <p v-if="result" class="time">{{ result.time }}</p>
          <p v-if="!result" class="time">---</p>
          <div v-if="result" class="btn" :class="result.isPlaying ? 'pause' : 'play'" @click="playButton"></div>
          <div v-if="desktop" class="btn toggler" @click="playback = !playback"></div>
        </div>
      </div>

      <tagSelectDialog class="data_point" v-if="dataPoint" @selected="tagSelected" :locationId="locationSelectedId" :vehicleId="currentDropletId"></tagSelectDialog>
      
      <div class="universal_modal groups_modal" v-if="newProfile">
        <h3>Data Groups</h3>
        <profileDrop :startingId="'0'" :states="profileStates" :enabled="profileDropDownEnabled" @selection="profileSelected"></profileDrop>
        <div class="buttons">
          <div class="button secondary" @click="newProfile = false">Cancel</div>
          <div class="button" @click="loadProfile">Load</div>
        </div>
      </div>

      <div class="universal_modal save_modal" v-if="save || saveAs">
        <h3 v-if="save">Please enter the group name</h3>
        <h3 v-if="saveAs">Please enter a name for this profile</h3>
        <input type="text" v-model="profileName">
        <div class="buttons">
          <div class="button secondary" @click="save = false; saveAs = false;">Cancel</div>
          <div class="button" v-if="save" @click="saveProfile">Save</div>
          <div class="button" v-if="saveAs" @click="saveAsProfile">Save</div>
        </div>
      </div>

      <div class="mts_data"  v-if="displayData == 'mts'">
        <!-- MTS -->
        <div class="mts_left">
          <radialDisp class="radialDisp" v-if="result" :valin="result.MachineStatus_Radial_Displacement" ></radialDisp>
          <steeringAngle class="steeringAngleDisp" v-if="result" :valin_actual="result.MachineStatus_Actual_Steering_Angle" :valin_theoretical="result.MachineStatus_Theoretical_Steering_Angle"></steeringAngle>
        </div>

        <div class="mts_center">
          <div>
            <dial1 class="dial" v-if="result" :valin="result.MachineStatus_Brake_Force" :title="Brake"></dial1>
            <p>Brake %</p>
          </div>
          <div>
            <dial1 class="dial" v-if="result" :valin="result.MachineStatus_Current_Speed_Feedback"></dial1>
            <p>IPS</p>
          </div>
          <div>
            <stateInd1 v-if="result" class="op_mode" :valin="opmode" :shape="0" :states=textStates></stateInd1>
            <p>Operational Mode</p>
          </div>
          <div>
            <dial1 v-if="result" class="dial" :valin="result.MachineStatus_Swing_Tail_Opening" ></dial1>
            <p>Swing Tail Opening</p>
          </div>
          <div>
            <dial1 v-if="result" class="dial" :valin="result.MachineStatus_Angle_Of_Lvc_In_Relationship_To_Aircraft" ></dial1>
            <p>Lift Vehicle Chassis</p>
          </div>
        </div>

        <div class="mts_right">
          <div class="eight_gages">
            <div>
              <p>Tangential Load (Ibs)</p>
              <dial2 v-if="result" class="eight_dial" :title="result.Compliance_P1_Tangentail_Load_Feed_Back" ></dial2>
            </div>
            <div>
              <p>System in By–Pass</p>
              <ind2 v-if="result" class="eight_light" :valin="result.MachineStatus_System_In_Bypass" :shape="0" :states=colorStates></ind2>
            </div>
            <div>
              <p>A/P Load (Ibs)</p>
              <dial2 v-if="result" class="eight_dial" :title="result.MachineStatus_AP_Load"></dial2>
            </div>
            <div>
              <p>P257</p>
              <ind2 v-if="result" class="eight_light" :valin="result.MachineStatus_P257_Cables_Connected" :shape="0" :states=colorStates></ind2>
            </div>
            <div>
              <p>Direction</p>
              <dial2 v-if="result" class="eight_dial" :title="direction"></dial2>
            </div>
            <div>
              <p>Parking</p>
              <ind2 v-if="result" class="eight_light" :valin="result.MachineStatus_Parking_Brake" :shape="0" :states=colorStates></ind2>
            </div>
            <div>
              <p>Transport Mode</p>
              <ind2 v-if="result" class="eight_light" :valin="result.MachineStatus_Transport_Speed_Enabled" :shape="0" :states=colorStates></ind2>
            </div>
            <div>
              <p>Home</p>
              <ind2 v-if="result" class="eight_light" :valin="result.MachineStatus_Home" :shape="0" :states=colorStates></ind2>
            </div>
          </div>
          <div class="wevlet">
            <wevlet v-if="result"
                    :hydPressure="result.MastHydraulic_H4_Hydraulic_Pressure_Sensor" 
                    :nitPressure="result.MastHydraulic_H9_Nitrogen_Pressure_Sensor" 
                    :mastLevel="result.MastHydraulic_H7_Accumulator_Piston_Stroke" 
                    :hydLevel="result.MachineStatus_Mast_Height" 
                    :leftMast="result.MachineStatus_Mast_Height"
                    :dirtyFilter="result.MastHydraulic_Dirty_Filter_Switch" 
                    :problemValve1="result.MastHydraulic_H5_Lh_Cylinder_Blocking_Valve"
                    :problemValve2="result.MastHydraulic_H6_Rh_Cylinder_Blocking_Valve"
                    :problemValve3="result.MastHydraulic_H1_Main_Blocking_Solenoid">
            </wevlet>
            <wevlet v-if="!result"
                    :hydPressure=0
                    :nitPressure=0
                    :mastLevel=0
                    :hydLevel=0
                    :leftMast=0
                    :dirtyFilter=0
                    :problemValve1=0
                    :problemValve2=0
                    :problemValve3=0>
            </wevlet>
          </div>
        </div>
      </div>

      <div class="lcl_data" v-if="displayData == 'lcl'">
        <div class="lcl_left">
          <div class="lcl_left_one">
            <p>Side Shift Front Left<ind2 v-if="result" class="ind2Disp" :valin="result.MachineStatus_Front_Side_Shift_Switch_Status_Front_Left" :shape="0" :states=colorStates></ind2></p>
            <p>Side Shift Rear Left<ind2 v-if="result" class="ind2Disp" :valin="result.MachineStatus_Front_Side_Shift_Switch_Status_Rear_Left" :shape="0" :states=colorStates></ind2></p>
            <p>Roll Switch Left<ind2 v-if="result" class="ind2Disp" :valin="result.MachineStatus_Roll_Switch_Status_Left_Roll" :shape="0" :states=colorStates></ind2></p>
            <p>Side Shift Front Right<ind2 v-if="result" class="ind2Disp" :valin="result.MachineStatus_Front_Side_Shift_Switch_Status_Front_Right" :shape="0" :states=colorStates></ind2></p>
            <p>Side Shift Rear Right<ind2 v-if="result" class="ind2Disp" :valin="result.MachineStatus_Rear_Side_Shift_Switch_Status_Rear_Right" :shape="0" :states=colorStates></ind2></p>
            <p>Roll Switch Right<ind2 v-if="result" class="ind2Disp" :valin="result.MachineStatus_Roll_Switch_Status_Right_Roll" :shape="0" :states=colorStates></ind2></p>
            <p>Stabilizer Up<ind2 v-if="result" class="ind2Disp" :valin="result.MachineStatus_Stabilizer_Switch_Status_Up" :shape="0" :states=colorStates></ind2></p>
            <p>Stabilizer Moving<ind2 v-if="result" class="ind2Disp" :valin="result.MachineStatus_Stabilizer_Switch_Status_Moving" :shape="0" :states=colorStates></ind2></p>
            <p>Stabilizer Down<ind2 v-if="result" class="ind2Disp" :valin="result.MachineStatus_Stabilizer_Switch_Status_Down" :shape="0" :states=colorStates></ind2></p>
          </div>
          <div class="lcl_left_two">
            <p>Guidance<ind2 v-if="result" class="ind2Disp" :valin="result.MachineStatus_Guidance" :shape="0" :states=colorStates></ind2></p>
            <p>Lift Select Rear<ind2 v-if="result" class="ind2Disp" :valin="result.MachineStatus_Lift_Selection_Switch_Status_Rear" :shape="0" :states=colorStates></ind2></p>
            <p>Lift Select Front<ind2 v-if="result" class="ind2Disp" :valin="result.MachineStatus_Lift_Selection_Switch_Status_Front" :shape="0" :states=colorStates></ind2></p>
          </div>
          <div class="lcl_left_three">
            <div class="transfer_mode">
              <p>Transfer<br/> Mode</p>
              <ind1 v-if="result" class="transfer" :valin="result.Transfer_Transfer_Enabled" :shape="1" :states=colorStates></ind1>
            </div>
            <div class="list_inside">
              <p>Front End Stop<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Front_End_Stop_Switch_Status" :shape="0" :states=colorStates></ind2></p>
              <p>Transfer FWD<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Transfer_Direction_Fwd" :shape="0" :states=colorStates></ind2></p>
              <p>Transfer REV<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Transfer_Direction_Rev" :shape="0" :states=colorStates></ind2></p>
              <p>Rear End Stop<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Rear_End_Stop_Switch_Status" :shape="0" :states=colorStates></ind2></p>
            </div>
            <dial1 class="xfer" :valin="result.Transfer_Transfer_Speed" :title="'XFER'"></dial1>
          </div>
        </div>
        <div class="lcl_middle">
          <p>Zone 1<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Zone_01_Status" :shape="0" :states=colorStates></ind2></p>
          <p>Zone 2<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Zone_02_Status" :shape="0" :states=colorStates></ind2></p>
          <p>Zone 3<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Zone_03_Status" :shape="0" :states=colorStates></ind2></p>
          <p>Zone 4<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Zone_04_Status" :shape="0" :states=colorStates></ind2></p>
          <p>Zone 5<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Zone_05_Status" :shape="0" :states=colorStates></ind2></p>
          <p>Zone 6<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Zone_06_Status" :shape="0" :states=colorStates></ind2></p>
          <p>Zone 7<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Zone_07_Status" :shape="0" :states=colorStates></ind2></p>
          <p>Zone 8<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Zone_08_Status" :shape="0" :states=colorStates></ind2></p>
          <p>Zone 9<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Zone_09_Status" :shape="0" :states=colorStates></ind2></p>
          <p>Zone 10<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Zone_10_Status" :shape="0" :states=colorStates></ind2></p>
          <p>Zone 11<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Zone_11_Status" :shape="0" :states=colorStates></ind2></p>
          <p>Zone 12<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Zone_12_Status" :shape="0" :states=colorStates></ind2></p>
          <p>Zone 13<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Zone_13_Status" :shape="0" :states=colorStates></ind2></p>
          <p>Zone 14<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Zone_14_Status" :shape="0" :states=colorStates></ind2></p>
          <p>Zone 15<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Zone_15_Status" :shape="0" :states=colorStates></ind2></p>
          <p>Zone 16<ind2 v-if="result" class="ind2Disp" :valin="result.Transfer_Zone_16_Status" :shape="0" :states=colorStates></ind2></p>
        </div>
        <div class="lcl_right">
          <div class="angle_displays">
            <rollAngle v-if="result" class="rollAngleDisp" :valin="result.MachineStatus_Roll_Angle" ></rollAngle>
            <heightAndPitch v-if="result" class="heightAndPitchDisp" :val_left="result.MachineStatus_Rear_Height" :val_right="result.MachineStatus_Front_Height"></heightAndPitch>
            <yaw v-if="result" class="yawDisp" :val_left="result.MachineStatus_Rear_Side_Shift" :val_right="result.MachineStatus_Front_Side_Shift"></yaw>
            <imageInd1 v-if="result" class="imageIndDisp" :valin="result.MachineStatus_Driving_Mode" :images=imageStates></imageInd1>
            <dial1 v-if="result" class="fuel" style="font-size: 0.62rem;" :valin="fuelLevel" :title="'Fuel'"></dial1>
            <dial1 v-if="result" class="mph" style="font-size: 0.62rem;" :valin="result.MachineStatus_Drive_Speed" :title="'MPH'"></dial1>
          </div>

          <div class="pfr">
            <div>
              <p>Preheating</p>
              <div>
                <span>E1<ind2 v-if="result" class="ind2Disp" :valin="result.Engine1_Engine_Preheating" :shape="0" :states=colorStates></ind2></span><span>E2<ind2 class="ind2Disp" :valin="result.Engine2_Engine_Preheating" :shape="0" :states=colorStates></ind2></span>
              </div>
            </div>
            <div>
              <p>Fault</p>
              <div>
                <span>E1<ind2 v-if="result" class="ind2Disp" :valin="result.Engine1_Engine_Fault" :shape="0" :states=colorStates></ind2></span><span>E2<ind2 class="ind2Disp" :valin="result.Engine2_Engine_Fault" :shape="0" :states=colorStates></ind2></span>
              </div>
            </div>
            <div>
              <p>Running</p>
              <div>
                <span>E1<ind2 v-if="result" class="ind2Disp" :valin="result.Engine1_Engine_Running" :shape="0" :states=colorStates></ind2></span><span>E2<ind2 class="ind2Disp" :valin="result.Engine2_Engine_Running" :shape="0" :states=colorStates></ind2></span>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>

    <div class="data_monitor">
      <h3>Data Monitor</h3>
      <div class="data_monitor_inside">
        <div class="data_group">Data Group <span>{{currentLoadedProfile != '' ? 'NONE' : currentLoadedProfile}}</span></div>
        <div class="loaded_datapoints">Loaded Data Points <span @click="dataPoint = true"></span></div>

        <div class="datapoints">          
          <jsonView class="jsonViewDisp" :tagsIn=result :isFilteredIn=true :filterArrayIn=filterArray @deleteItem="deleteItemFromFilter"></jsonView>
        </div>
      </div>
      <div class="dvrProfileButtons">
        <p class="btn btn-load" @click="newProfile = true"><span>Open</span></p>
        <p class="btn btn-clear" @click="clearProfile"><span>New</span></p>
        <p class="btn btn-save" @click="save = true"><span>Save</span></p>
        <p class="btn btn-saveas" @click="saveAs = true"><span>Save As</span></p>
        <p class="btn btn-delete" @click="deleteProfile"><span>Delete</span></p>
      </div>
    </div>

    <div class="playback_settings" :style="!playback && windowWidth >= 1300 || !playback && windowWidth <= 820 ? {display: 'none'} : null">
      <h3>Playback Settings<span @click="playback = !playback"></span></h3>
      <dvrdata :startstop="startstop" :dropletid="currentDropletId" @new-data="UpdateData"></dvrdata>
    </div>
    <div class="context_shadow" v-if="dataPoint || playback && windowWidth <= 820 || save || saveAs || newProfile" @click="dataPoint = false; playback = false; save = false; saveAs = false; newProfile = false"></div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import dvrdata from '../components/boeing/dvr.vue';
  import radialDisp from '../components/boeing/radialDisplacementGauge.vue';
  import steeringAngle from '../components/boeing/steeringAngleGauge.vue';
  import dial1 from '../components/boeing/dialGauge.vue';
  import dial2 from '../components/boeing/dialGauge2.vue';
  import ind1 from '../components/boeing/indicator.vue';
  import ind2 from '../components/boeing/indicator.vue';
  import stateInd1 from '../components/boeing/textIndicator.vue';
  import wevlet from '../components/boeing/wevlet.vue';
  import rollAngle from '../components/boeing/levelGauge.vue';
  import heightAndPitch from '../components/boeing/heightAndPitchGauge.vue';
  import yaw from '../components/boeing/yawGauge.vue';
  import imageInd1 from '../components/boeing/imageStateIndicator.vue';
  import jsonView from '../components/boeing/jsonViewer.vue';
  import ProfileDrop from '../components/dropdowns/dropdown1.vue';
  import locationDrop from '../components/dropdowns/dropdown2.vue';
  import vehicleDrop from '../components/dropdowns/dropdown2.vue';
  import TagSelectDialog from '../components/boeing/tagSelectorMaintenance.vue';
  
  let displayData = ref('');
  let startstop = ref(0);
  let save = ref(false);
  let saveAs = ref(false);
  let profileName = ref('');
  let newProfile = ref(false);
  let playback = ref(false);
  let dataPoint = ref(false);
  let result = ref();
  let imageStates = ref("");
  let windowWidth = ref(window.innerWidth);
  imageStates.value = {
    "3":"https://boeing.dreamlifter.wave7.co/screens/projects/BDL/assets/BDL-Mode-All.svg",
    "5":"https://boeing.dreamlifter.wave7.co/screens/projects/BDL/assets/BDL-Mode-Carousel.svg",
    "4":"https://boeing.dreamlifter.wave7.co/screens/projects/BDL/assets/BDL-Mode-Crab.svg",
    "1":"https://boeing.dreamlifter.wave7.co/screens/projects/BDL/assets/BDL-Mode-Front.svg",
    "0":"https://boeing.dreamlifter.wave7.co/screens/projects/BDL/assets/BDL-Mode-None.svg",
    "2":"https://boeing.dreamlifter.wave7.co/screens/projects/BDL/assets/BDL-Mode-Rear.svg",
    "6":"https://boeing.dreamlifter.wave7.co/screens/projects/BDL/assets/BDL-Mode-Sideways.svg",
    "undefined":"https://boeing.dreamlifter.wave7.co/screens/projects/BDL/assets/BDL-Mode-None.svg"
  };
  
  const desktop = computed(() => {
    return window.screen.width >= 1300 ? true : false
  })

  const fuelLevel = computed(() => {
    const val = result.value.MachineStatus_Fuel_Level;
    return 100*(val/32568).toFixed(0);
  })

  const wevletDataReady = computed(() => {
    if(result.MastHydraulic_H4_Hydraulic_Pressure_Sensor && 
        result.MastHydraulic_H9_Nitrogen_Pressure_Sensor && 
        result.MachineStatus_Mast_Height  && 
        result.MachineStatus_Mast_Height &&
        result.MachineStatus_Mast_Height &&
        result.MastHydraulic_Dirty_Filter_Switch &&
        result.MastHydraulic_H5_Lh_Cylinder_Blocking_Valve && 
        result.MastHydraulic_H6_Rh_Cylinder_Blocking_Valve && 
        result.MastHydraulic_H1_Main_Blocking_Solenoid) {
      return true;
    } else {
      return false;
    }
  })

  const direction = computed(() => {
    const val = result.value;
    if(val.MachineStatus_Slow_Forward == "1")
    {
        return "FWD";
    }
    else if(val.MachineStatus_Slow_Reverse == "1")
    {
        return "REV";
    }
    else if(val.MachineStatus_Fast_Forward == "1")
    {
        return "FWD";
    }
    else if(val.MachineStatus_Fast_Reverse == "1")
    {
        return "REV";
    }
    else if(val.MachineStatus_Neutral_Position == "1")
    {
        return "N";
    }
    else
    {
        return "N/A";
    }
  });

  const opmode = computed(() => {
    const val = result.value;
    if(val.MachineStatus_Low_Speed_Arc_Travel == "1")
    {
        return 0;
    }

    else if(val.MachineStatus_High_Speed_Arc_Travel == "1")
    {
        return 1;
    }

    else if (val.MachineStatus_Arc_Travel_Authorized == "1")
    {
        return 2;
    }

    else if((val.MachineStatus_Phase_3_Mast_Lift == "1") || (val.MachineStatus_Phase_3_Mast_Lowering == "1"))
    {
        return 3;
    }

    else if((val.MachineStatus_Phase_2_Mast_Lift == "1") || (val.MachineStatus_Phase_2_Mast_Lowering == "1"))
    {
      return 4;
    }
    else if((val.MachineStatus_Phase_1_Mast_Lift == "1") || (val.MachineStatus_Phase_1_Mast_Lowering == "1"))
    {
      return 5;
    }
    else
    {
        return 6;
    }
  })
  
  let textStates = ref("");
  textStates.value = [
    "Low Speed",
    "High Speed",
    "Authorized",
    "Assuming Load / Unloading",
    "Hook Ups / Disconnecting",
    "Alignment / Disembark",
    ""
  ];

  let colorStates = ref("");
  colorStates.value = [
    "#333333",
    "#00a859"
  ];

  //----------------- DVR PROFILE MANAGEMENT -------------------------------
  let profileStates = ref({});
  let currentLoadedProfile = ref("");
  let profileDropDownEnabled = ref(false);

  //let currentlySelectedProfile = "";
  let currentlySelectedProfileRef = ref("");
  const profilesUrl = "https://boeing.dreamlifter.wave7.co/scripts/dvr/getDvrProfile.php";
  const profilesSetUrl = "https://boeing.dreamlifter.wave7.co/scripts/dvr/setDvrProfile.php";

  import { inject } from 'vue';
  let isarchive = inject('isarchive');
  if(isarchive==undefined) isarchive="false";

  function playButton()
  {
    if(result.value.isPlaying) {
      startstop.value = 0;
    } else {
      startstop.value = 1;
    }
  }

  function initProfilesDropDown() {
    fetch(profilesUrl+"?list", {
      headers: { 'Content-type': 'application/json' },
      }).then((res) => res.json()).then((response) => {
          profileStates.value = response;
          currentlySelectedProfileRef.value = Object.keys(profileStates.value)[0];
      }).catch((error) => {
          console.log("LOCATION FETCH ERROR " + error);
    });
  }

  function profileSelected(profileName) {
    console.log("csp",profileName)
    currentlySelectedProfileRef.value = profileName;
  }

  function loadProfile() {
    fetch(profilesUrl+"?gname="+currentlySelectedProfileRef.value, {
    headers: { 'Content-type': 'application/json' },
    }).then((res) => res.json()).then((response) => {
      console.log(response);
      filterArray.value = JSON.parse(response.filterstring);
      console.log(filterArray.value);
      currentLoadedProfile.value = currentlySelectedProfileRef.value;
      newProfile.value = false;
    }).catch((error) => {
        console.log("LOAD PROFILE FETCH ERROR " + error);
    });
  }

  function saveProfile() {
    //TO DO:  This function needs to check if the user logged in is an ADMIN
    const fl = encodeURI(JSON.stringify(filterArray.value));
    let url = "";

    let gname = currentLoadedProfile.value;
    if(gname=="")
    {
      // gname = prompt("Please enter the group name","");
      gname = profileName.value;
      currentLoadedProfile.value = gname;
      url = profilesSetUrl+"?new=1&dropletid=" + currentDropletId + "&gname="+encodeURI(gname)+"&filterlist="+fl;
    }
    else
    {
      url = profilesSetUrl+"?update=1&dropletid=" + currentDropletId + "&gname="+encodeURI(gname)+"&filterlist="+fl;
    }

    fetch(url, {
      headers: { 'Content-type': 'application/json' },
      }).then((response) => {
          console.log(response);
        initProfilesDropDown();
        save.value = false;
        saveAs.value = false;
      }).catch((error) => {
          console.log("SAVE PROFILE FETCH ERROR " + error);
    });
  }

  function saveAsProfile() {
    currentLoadedProfile.value = "";
    saveProfile();
  }

  function deleteProfile() {
    //TO DO:  This function needs to check if the user logged in is an ADMIN
    let res = prompt("You are about to delete the profile " + currentlySelectedProfileRef.value + "  Type YES if you want to proceed");
    if(res==null) res="no";
    if(res.toLowerCase()=="yes")
    {
      const gname = currentlySelectedProfileRef.value;
      currentLoadedProfile.value = "";
      filterArray.value = [];
      const url = profilesSetUrl+"?delete=1&dropletid=" + currentDropletId + "&gname="+encodeURI(gname);
      console.log("DELETE URL",url);
      fetch(url, {
        headers: { 'Content-type': 'application/json' },
        }).then((response) => {
            initProfilesDropDown();
            console.log(response);
        }).catch((error) => {
            console.log("DELETE PROFILE FETCH ERROR " + error);
      });
    }
  }

  function clearProfile() {
    filterArray.value = [];
  }

  initProfilesDropDown();
  //----------------- END OF DVR PROFILE MANAGEMENT -------------------------------

  //----------------- LOCATION AND VEHICLE DROP DOWN SELECTION -------------------------------

  let currentlySelectedGroupId = "";
  let currentDropletId = "";
  let locationSelectedId = "";
  let locationDropDownEnabled = ref(false);
  let vehicleDropDownEnabled = ref(false);
  let tagGroupDropDownEnabled = ref(false);
  let tagDropDownEnabled = ref(false);

  let locationStates = ref("");
  let vehicleStates = ref("");
  let tagGroupStates = ref("");
  let tagStates = ref("");

  locationStates.value = [];
  vehicleStates.value = [];
  tagGroupStates.value = [];
  tagStates.value = [];

  let getLocationsUrl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?locSel";
  let getVehicleUrl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?dropSel&location=";
  let getTagGroupsUrl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?groupSel&droplet=";
  let getTagsUrl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?tagSel&group=";

  if(isarchive=="true") {
    getLocationsUrl = "https://archive.boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?locSel";
    getVehicleUrl = "https://archive.boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?dropSel&location=";
    getTagGroupsUrl = "https://archive.boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?groupSel&droplet=";
    getTagsUrl = "https://archive.boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?tagSel&group=";
  }
 console.log("Location URL",getLocationsUrl);
  fetch(getLocationsUrl, {
    headers: { 'Content-type': 'application/json' },
    }).then((res) => res.json()).then((response) => {
        console.log("LOCATIONS:",response);
        locationStates.value = response;
        locationDropDownEnabled.value = true;
    }).catch((error) => {
        console.log("LOCATION FETCH ERROR " + error);
  });

  function locationSelected(id) {
    startstop.value = 0;
    locationSelectedId = id;
    fetch(getVehicleUrl+id, {
      headers: { 'Content-type': 'application/json' },
      }).then((res) => res.json()).then((response) => {
          vehicleStates.value = response;
          vehicleDropDownEnabled.value = true;
      }).catch((error) => {
          console.log("VEHICLE FETCH ERROR " + error);
    });
  }

  function idToVehicleType(id) {
    let vtype = "";
    switch(id) {
      case "110":
      case "501":
      case "210":
      case "111":
      case "211":
      case "310":
      case "410":
      case "311":
      case "411":
      case "500":
        vtype="lcl";
        break;
      case "100":
      case "511":
      case "200":
      case "201":
      case "101":
      case "300":
      case "400":
      case "301":
      case "510":
      case "401":
        vtype="mts";
    }
    return vtype;
  }
              
  function vehicleSelected(id) {
    startstop.value = 0;
    currentDropletId = id;
    profileDropDownEnabled.value = true;
    console.log("DROPLETID",currentDropletId);
    displayData.value = idToVehicleType(currentDropletId);
    fetch(getTagGroupsUrl+id, {
      headers: { 'Content-type': 'application/json' },
      }).then((res) => res.json()).then((response) => {
          tagGroupStates.value = response;
          tagGroupDropDownEnabled.value = true;
      }).catch((error) => {
          console.log("TAG GROUP FETCH ERROR " + error);
    });
  }
  //----------------------------END OF LOCATION AND VEHICLE DROP DOWNS -----------------------------

  //----------------- GROUP AND TAG DROP DOWN SELECTION -------------------------------
  let currentlySelectedTagId = "";
  function tagGroupSelected(id) {
    currentlySelectedGroupId = id;
    fetch(getTagsUrl+id, {
      headers: { 'Content-type': 'application/json' },
      }).then((res) => res.json()).then((response) => {
          tagStates.value = response;
          tagDropDownEnabled.value = true;
      }).catch((error) => {
          console.log("TAGS FETCH ERROR " + error);
    });
  }

  function tagSelected(tagInfo) {
    //filterArray.value
    //currentlySelectedTagId = id;
    console.log(tagInfo);
    addTagToFilter(tagInfo.group, tagInfo.name);
    dataPoint.value = false;
  }

  let filterArray = ref([]);

  function addTagToFilter(group,name) {
    const tagname =  (group + "_" + name).split(" ").join("_");
    filterArray.value.push(tagname);
    result.value[tagname]="...";
  }

  function deleteItemFromFilter(item) {
    const index = filterArray.value.indexOf(item);
    filterArray.value.splice(index,1);
  }
  //----------------- END OF GROUP AND TAG DROP DOWN SELECTION ------------------------------- 


  function UpdateData(newdata) {
    result.value = newdata;
  }

</script>


<style lang="scss" scoped>
  .main_v8 {
    // top: 64px !important;
    padding: 0px;
    background-color: #fff;
  }
  .save_modal,.groups_modal{
    background-color: #fff;
    position: fixed;
    z-index: 2;
    border-radius: 16px;
    padding: 20px;
    width: 450px;
    max-width: calc(100% - 30px);
    h3{
      margin-bottom: 20px;
    }
    input{
      outline: none;
      padding-left: 5px;
      border: 1px solid #bcbcbc;
      height: 40px;
      line-height: 40px;
      display: inline-block;
      width: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      font-size: 1rem;
    }
    .buttons{
      margin-top: 20px;
      .button{
        width: calc(50% - 8px);
      }
      .button:first-child{
        margin-right: 16px;
      }
    }
  }
  .data_point{
    position: fixed;
    width: 500px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
  }
  .data_operations{
    background-color: #fff;
    border-radius: 12px;
    box-sizing: border-box;
    width: calc(100% - 385px);
    height: 100%;
    font-size: 19px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1500px) {
      width: calc(100% - 350px);
      height: initial;
    }
    @media screen and (max-width: 1300px) {
      position: relative;
      width: 100%;
    }
    @media screen and (max-width: 700px) {
      height: auto;
    }
    .controls{
      height: 74px;
      background-color: #f2f2f2;
      padding-left: 20px;
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 1000px) {
        height: auto;
      }
      @media screen and (max-width: 700px) {
        flex-direction: column;
        height: auto;
        align-items: start;
        padding: 0px 20px 10px;
      }
      h3{
        margin: 0 20px 0 0;
        height: 74px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 700px) {
          height: 55px;
          line-height: 55px;
        }
      }
      .dropdowns{
        display: flex;
        flex-direction: row;
        align-items: center;
        @media screen and (max-width: 1000px) {
          flex-direction: column;
          margin: 10px 0;
          align-items: end;
        }
        @media screen and (max-width: 800px) {
          align-items: baseline;
        }
        @media screen and (max-width: 700px) {
          width: 100%;
          margin: 0 0 10px 0;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: space-between;
        }
        .dropdown{
          display: flex;
          flex-direction: row;
          margin-right: 15px;
          align-items: center;
          @media screen and (max-width: 1420px) {
            flex-direction: column;
            align-items: baseline;
          }
          @media screen and (max-width: 1300px) {
            flex-direction: row;
            align-items: center;
          }
          @media screen and (max-width: 800px) {
            flex-direction: column;
            align-items: baseline;
            width: calc(50% - 7px);
            margin-right: 0px;
          }
          .custom-select{
            height: 100%;
          }
          >span{
            margin-right: 10px;
            @media screen and (max-width: 700px) {
              align-self: baseline;
              margin-bottom: 5px;
            }
          }
        }
        .dropdown:first-child{
          
          @media screen and (max-width: 1000px) {
            margin-bottom: 10px; 
          }
        }
        .locationDropDisp,.vehicleDropDisp {
          height: 100%;
          width: 165px;
          @media screen and (max-width: 700px) {
            width: 100%;
          }
        }
      }
      .controls_right{
        display: flex;
        // margin-left: auto;
        flex-direction: row;
        align-items: center;
        @media screen and (max-width: 700px) {
          margin-left: 0;
          width: 100%;
          flex-direction: row;
          justify-content: center;
        }
        .time{
          font-size: 1rem;
          background-color: #787878;
          color: #fff;
          padding: 0 14px;
          border-radius: 8px;
          width:230px;
          text-align: center;
        }
        .play{
		      background-image: url(../../public/img/boeing/ma/play.svg);
          background-position: left 55% center;
        }
        .pause{
		      background-image: url(../../public/img/boeing/ma/pause.svg);
          background-position: center;
        }
        .play,.pause{
          border-radius: 26px;
          background-color: #787878;
          background-repeat: no-repeat;
          background-size: 18px;
        }
        .toggler{
          background-image: url(../../public/img/boeing/settings.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 35px;
          @media screen and (max-width: 1300px){
            display: none;
          }
          @media screen and (max-width: 820px) {
            display: block;
          }
        }
        .time,.play,.toggler,.pause{
          margin: 0 10px 0 0;
          height: 52px;
          line-height: 52px;
        }
        .toggler,.play,.pause{
          cursor: pointer;
          aspect-ratio: 1/1;
        }
      }
    }
    .lcl_data,.mts_data{
      padding: 0 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-grow: 1;
      >div:not(:last-child){
        @media screen and (min-width: 1500px){
          margin-right: 20px;
        }
        @media screen and (min-width: 520px){
          margin-right: 10px;
        }
      }
    }
    .mts_data{
      justify-content: space-around;
      @media screen and (max-width: 820px) {
        flex-direction: column;
        position: relative;
        bottom: auto;
      }
      .mts_left{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        @media screen and (max-width: 820px) {
          margin: 20px 0 0;
          flex-direction: row;
        }
        @media screen and (max-width: 480px) {
          margin: 10px 0 0;
          flex-direction: column;
          align-items: center;
        }
        .radialDisp {
          width: 300px;
          aspect-ratio: 1/1;
          @media screen and (max-width: 820px) {
            margin-right: 10px;
          }
          @media screen and (max-width: 480px) {
            margin-right: 0px;
            margin-bottom: 10px;
          }
        }
        .steeringAngleDisp {
          width: 300px;
          aspect-ratio: 1/1;
        }
      }
      .mts_center{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 0;
        @media screen and (max-width: 820px) {
          flex-direction: initial;
          justify-content: space-around;
          flex-wrap: wrap;
          padding: 20px 0 10px;
        }
        >div{
          background-color: #f2f2f2;
          border-radius: 5px;
          max-width: 130px;
          @media screen and (max-width: 1000px) {
            margin-bottom: 10px;
          }
          .dial{
            width: 80px;
            aspect-ratio: 1/1;
            margin: 0 auto;
          }
          .op_mode{
            height: calc(3rem + 8px);
            margin: 5px 5px 0;
          }
          p{
            margin: 0 10px;
            color: #787878;
            text-align: center;
          }
        }
        >div:not(:last-child){
          @media screen and (max-width: 820px) {
            margin-right: 10px;
          }
        }
      }
      .mts_right{
        display: flex;
        align-items: center;
        flex-direction: column;
        .eight_gages{
          border: 1px solid #bcbcbc;
          border-radius: 16px;
          padding: 10px 0 0 10px;
          display: flex;
          flex-wrap: wrap;
          max-width: 500px;
          margin-top: 10px;
          @media screen and (max-width: 820px) {
            margin-top: 0px;
          }
          >div{
            width: calc(50% - 10px);
            margin-right: 10px;
            margin-bottom: 10px;
            display: flex;
            border-radius: 16px;
            background-color: #7878780D;
            height: 60px;
            p{
              margin: 0;
              align-self: center;
              text-align: right;
              flex: 1;
              color: #787878;
            }
            .eight_light{
              height: 25px;
              aspect-ratio: 1/1;
              align-self: center;
              margin-left: 10px;
              margin-right: 10px;
            }
            .eight_dial{
              aspect-ratio: 8/5;
            }
          }
        }
        .wevlet{
          padding: 20px 20px 0;
          flex-shrink: 0;
          width: calc(100% - 40px);
          @media screen and (max-width: 560px) {
            padding: 20px 0 0;
            flex-shrink: 0;
            width: 100%;
          }
          // height: calc(100% - 40px);
        }
      }
    }
    .lcl_data{
      @media screen and (max-width: 1100px) {
        flex-direction: initial;
        flex-wrap: wrap;
        justify-content: space-around;
      }
      .lcl_right{
        min-width: 350px;
        max-width: 540px;
        flex-grow: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        .angle_displays{
          display: flex;
          flex-wrap: wrap;
          position: relative;
          margin: auto 0;
          .rollAngleDisp,.heightAndPitchDisp,.yawDisp,.imageIndDisp {
            width: calc(50% - 4px);
            box-sizing: border-box;
            aspect-ratio: 1/1;
            margin: 2px;
          }
          .fuel{
            top: 50%;
            left: 53%;
            height: 80px;
          }
          .mph{
            top: 50%;
            right: 5%;
            height: 80px;
          }
          .fuel,.mph{
            position: absolute;
            aspect-ratio: 1/1;
          }
          .imageIndDisp {}
          .rollAngleDisp{}
          .heightAndPitchDisp {}
          .yawDisp {}
        }
        .pfr{
          width: 100%;
          box-sizing: border-box;
          margin-top: 10px;
          margin-bottom: 10px;
          padding: 10px 20px;
          background-color: #f2f2f2;
          border-radius: 8px;
          >div{
            display: flex;
            flex-direction: row;
            align-content: center;
            height: 24px;
            color: #787878;
            p{
              margin: 0;
            }
            >div{
              margin-left: auto;
              span{
                display: inline-block;
                width: 54px;
                position: relative;
                margin-left: 20px;
                >div{
                  position: absolute;
                  right: 0px;
                  top: 0px;
                }
              }
            }
          }
          >div:not(:last-child){
            margin-bottom: 10px;
          }
        }
      }
      .lcl_middle{
        // height: calc(100% - 20px);
        margin-top: 10px;
        width: fit-content;
        min-width: 124px;
        border-radius: 16px;
        border: 1px solid #bcbcbc;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        box-sizing: border-box;
        @media screen and (max-width: 560px) {
          flex-direction: initial;
          flex-wrap: wrap;
        }
        p{
          color: #787878;
          margin: 0;
          padding-right: 30px;
          position: relative;
          box-sizing: border-box;
          @media screen and (max-width: 560px) {
            flex: 0 0 33.3333%;
            padding-left: 10px;
            margin-bottom: 10px;
            align-content: center;
            // padding-right: 0;
          }
          div{
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            @media screen and (max-width: 560px) {
              // right: 20px;
            }
          }
        }
      }
      .lcl_left{
        height: calc(100% - 20px);
        margin-top: 10px;
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        max-width: 545px;
        @media screen and (max-width: 1100px) {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          box-sizing: border-box;
        }
        @media screen and (max-width: 560px) {
          max-width: 100%;
        }
        >div{
          padding: 10px 0 0 10px;
          border-radius: 16px;
          border: 1px solid #bcbcbc;
        }
        >div:not(:last-child){
          @media screen and (max-width: 1300px) {
            margin-bottom: 10px;
          }
        }
        .lcl_left_one,.lcl_left_two,.lcl_left_four{
          display: flex;
          flex-wrap: wrap;
          p{
            margin: 0px;
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 6px 10px;
            width: calc(33% - 9px);
            color: #787878;
            background-color: #7878780D;
            border-radius: 16px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            text-align: right;
            align-items: center;
            @media screen and (max-width: 560px) {
              width: calc(50% - 10px);
              height: max-content;
              text-align: left;
              // padding
            }
            >div{
              margin-left: 10px;
              position: unset;
              @media screen and (max-width: 560px) {
                margin-left: auto;
                min-height: 24px;
              }
            }
          }
        }
        .lcl_left_three{
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          max-width: 100%;
          .transfer_mode{
            flex-direction: row;
            flex-direction: column;
            margin-right: 10px;
            p{
              color: #787878;
              text-align: center;
            }
            >div{
              aspect-ratio: 1/1;
            }
          }
          .list_inside{
            display: flex;
            flex-direction: column;
            p{
              margin: 0px;
              margin-right: 10px;
              margin-bottom: 10px;
              padding: 6px 10px;
              color: #787878;
              background-color: #7878780D;
              border-radius: 16px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              text-align: right;
              align-items: center;
              min-width: 210px;
              @media screen and (max-width: 560px) {
                min-width: initial;
                text-align: left;
              }
              >div{
                margin-left: auto;
                position: unset;
              }
            }
          }
          .xfer{
            align-self: center;
            height: 122px;
            aspect-ratio: 1/1;
            @media screen and (max-width: 560px) {
              height: 95px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  .playback_settings{
    position: absolute;
    background-color: #fff;
    box-sizing: border-box;
    width: 385px;
    height: 100%;
    top: 0px;
    right: 0px;
    overflow: hidden;
    box-shadow: 0px 4px 4px 0px #00000040;
    transition: all .2s ease-in-out;
    @media screen and (max-width: 1500px) {
      width: 350px;
    }
    @media screen and (max-width: 1300px) {
      position: relative;
      width: calc(50% - 40px);
      box-shadow: none;
      float: right;
      margin-right: 20px;
      padding-top: 20px;
    }
    @media screen and (max-width: 820px) {
      width: 100%;
      margin: 0;
      padding: 0 20px;
      height: initial;
      z-index: 2;
      position: fixed;
      top: 50%;
      left: 50%;
      width: calc(100% - 30px);
      transform: translate(-50%, -50%);
      padding-top: 20px;
      border-radius: 12px;
      // display: block !important;
    }
    h3{
      height: 74px;
      line-height: 74px;
      padding-left: 20px;
      margin: 0px;
      background-color: #f2f2f2;
      display: flex;
      box-sizing: border-box;
      @media screen and (max-width: 1300px) {
        height: 45px;
        line-height: 45px;
        border-radius: 12px;
      }
      @media screen and (max-width: 820px) {
        width: 100%;
      }
      span{
        display: inline-block;
        height: 74px;
        width: 74px;
        margin-left: auto;
        background-image: url(../../public/img/boeing/close.svg);
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        @media screen and (max-width: 1299px){
          display: none;
        }
        @media screen and (max-width: 820px){
          display: block;
          height: 45px;
          width: 45px;
        }
      }
    }
  }
  .data_monitor{
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px #00000040;
    box-sizing: border-box;
    width: 385px;
    height: 100%;
    top: 0px;
    right: 0px;
    overflow: hidden;
    @media screen and (max-width: 1500px) {
      width: 350px;
    }
    @media screen and (max-width: 1300px) {
      box-shadow: none;
      position: relative;
      float: left;
      width: calc(50% - 40px);
      margin-left: 20px;
      margin-bottom: 20px;
      height: fit-content;
      padding: 20px 20px 110px 20px;
    }
    @media screen and (max-width: 820px) {
      width: 100%;
      padding: 20px 20px 110px 20px;
      margin-left: 0px;
      margin-bottom: 0px;
    }
    .data_monitor_inside{
      max-height: calc(100% - 200px);
      overflow: auto;
      padding: 0 10px;
      .data_group{
        font-size: 1rem;
        margin-bottom: 7px;
        margin-top: 10px;
        span{
          color: #bcbcbc;
        }
      }
      .loaded_datapoints{
        position: relative;
        span{
          position: absolute;
          display: inline-block;
          height: 24px;
          aspect-ratio: 1/1;
          border-radius: 25%;
          background-color: #252525;
          right: 0px;
          top: 0px;
          color: #fff;
          background-image: url(../../public/img/boeing/ma/plus.svg);
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
    h3{
      height: 74px;
      line-height: 74px;
      padding-left: 10px;
      margin: 0px;
      background-color: #f2f2f2;
      @media screen and (max-width: 1300px) {
        height: 45px;
        line-height: 45px;
        border-radius: 12px;
      }
    }
    .dvrProfileButtons {
      position: absolute;
      bottom: 0px;
      width: 100%;
      box-sizing: border-box;
      padding: 0 15px 15px;
      display: flex;
      flex-direction: row;
      height: 95px;
      max-width: 400px;
      min-width: 320px;
      left: 50%;
      transform: translateX(-50%);
      .btn{
        height: 100%;
        width: 20%;
        color: #787878;
        text-align: center;
        font-size: 0.75rem;
        margin: 0px;
        position: relative;
        background-repeat: no-repeat;
        background-position: top center;
        cursor: pointer;
        span{
          width: 100%;
          left: 0px;
          right: 0px;
          display: inline-block;
          position: absolute;
          bottom: 0px;
        }
      }
      .btn-load{
        background-image: url(../../public/img/boeing/datatrending/open.svg);
      }
      .btn-clear{
        background-image: url(../../public/img/boeing/datatrending/new.svg);
      }
      .btn-save{
        background-image: url(../../public/img/boeing/datatrending/save.svg);
      }
      .btn-saveas{
        background-image: url(../../public/img/boeing/datatrending/save_as.svg);
      }
      .btn-delete{
        background-image: url(../../public/img/boeing/datatrending/delete.svg);
      }
    }
  }
  .context_shadow {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
  }
  .dualDial1Disp {
    position: absolute;
    top:20px;
    left:200px;
    height:200px;
    width:200px;
  }
  .dial1Disp {
    position: absolute;
    top:20px;
    left:120px;
    height:200px;
    width:200px;
  }
  .ind1Disp {
    position: absolute;
    top:200px;
    left:110px;
    height:100px;
    width:100px;
  }
  .ind2Disp {
    position: absolute;
    height:100%;
    aspect-ratio: 1/1;
    max-height: 25px;
  }
  .jsonViewDisp {
    overflow-y:auto;
    margin-top: 12px;
  }
</style>
