<template>
	<div class="dvrDisp">
		<div class="player">
			<div class="speed">
				<p>Change Playback Speed</p>
				<div class="change_speed">
					<div class="less" @click="SetPlayback(playbackRate-1)"></div>
					<div class="the_speed">{{ playbackRate }}x</div>
					<div class="more" @click="SetPlayback(playbackRate+1)"></div>
				</div>
			</div>
			
			<div class="request">
				<p>Request New Data</p>
				<div class="load_time">
					<p v-if="startstop==0">Desired Data Start</p>
                    <input :disabled="startstop==1" id="settime" type="datetime-local" class="datepicker" />
					<div v-if="startstop==0" class="button" @click="Reload();">Load data</div>
				</div>

				<div class="start_end">
					<div>
						<span>Tags</span> <p>{{ tagcount }}</p>
					</div>
					<div>
						<span>Start</span> <p>{{ startdatetime }}</p>
					</div>
					<div>
						<span>End</span> <p>{{ enddatetime }}</p>
					</div>
				</div>

				<div class="actions">
                    <table style="width:100%;">
                        <tr>
                            <td><div v-if="startstop==0" class="button play" @click="Inc();">INC</div></td>
                            <td><div v-if="startstop==0" class="button play" @click="Dec();">DEC</div></td>
                        </tr>
                    </table>
					<div v-if="startstop==0" class="button estimate" @click="Cue();">Estimate Operation Start</div>
				</div>
			</div>
			<livedatainternal
				:scanrate="scanRate"
				:controls="controls"
				@on-data="Render()">
			</livedatainternal>
		</div>
	</div>

</template>
  
<script setup>
    import { ref, toRefs, defineProps, defineEmits } from 'vue';
    import livedatainternal from '../livedata/datainternal.vue';

    //GET PROPERTIES FROM PARENT
    const props = defineProps({startstop: Number,dropletid: Number});
    const {startstop, dropletid} = toRefs(props);
    
    //SEND DATA BACK TO PARENT
    const emit = defineEmits(['new-data']);
    
    //Reactive Variables
    const scanRate = ref(250);
    const controls = ref(false);
    let timeslice = ref(0);
    let result = ref();
    let tagList = ref("");
    let reload = ref(0);
    let tcount = ref(0);
    let wheelinput = ref(0);
    let selectmultiply = ref(1);
    let virtualtime = ref("");
    let startdatetime = ref("");
    let enddatetime = ref("");
    let tagcount = ref(0);
    let datasource = ref("https://boeing.dreamlifter.wave7.co/scripts/dvr/get.php");
    let isPlaying = ref(false);
    let playbackRate = ref(1);
    let ineedreload = ref(0);
    let cue = ref(0);
    let vtime = ref("");
    let currentMultiply = ref("seconds");
    let dp = ref();
    let dataInMinutes = ref("");

    //Non reactive variables
    let initClock = true;
    let lastWheelValue = 0;
    let lastStartDateTime = 0;
    let dvrfp = true;
    let tickCount = 0;
    let dataProcessed = false;
    let index = 0;
    let lastIndex = 0;
    let tempo = {};
    let lastTagValue = [];
    let delta = 0;

    tagList.value = "";

    function SetPlayback(val) {
        if (val > 4 || val < 1) { 
            return
        }
        playbackRate.value = val;
        selectmultiply.value = val;
    }

    function Reload() {
        dvrfp = true;
        startdatetime.value = vtime.value;
    }

    function Cue() {
        Increment(cue.value,1);
    }

    function Inc() {
        Increment(1,(selectmultiply.value/1));
    }

    function Dec() {
        Increment(-1,(selectmultiply.value/1));
    }

    async function Render() {
        let t = new Date();
        let year = "";
        let month = "";
        let day = "";
        let hour = "";
        let minute = "";
        let second = "";

        if(startstop.value==1) isPlaying.value = true;
        if(startstop.value==0) isPlaying.value = false;

        //Set start to now if undefined
        if(initClock)
        {
            t = new Date();
            year = t.getFullYear();
            month = ('0' + (t.getMonth()+1)).slice(-2);
            day = ('0' + t.getDate()).slice(-2);
            hour = ('0' + t.getHours()).slice(-2);
            minute = ('0' + t.getMinutes()).slice(-2);
            second = ('0' + t.getSeconds()).slice(-2);
            startdatetime.value = year+"-"+month+"-"+day+" "+hour+":"+minute+":"+second;
            //vtime.value = year+"-"+month+"-"+day+"T"+hour+":"+minute+":"+second;
            document.getElementById("settime").value = year+"-"+month+"-"+day+"T"+hour+":"+minute+":"+second;
            enddatetime.value = year+"-"+month+"-"+day+" "+hour+":"+minute+":"+second;
            initClock = false;
        }
 
        //Check main wheel value
        delta = (wheelinput.value) - (lastWheelValue);
        lastWheelValue = wheelinput.value;
        if(startdatetime.value!==lastStartDateTime)
        {
            vtime.value = startdatetime.value;
        }
        lastStartDateTime = startdatetime.value;
        startdatetime.value = vtime.value;
        ineedreload.value = "0";
        reload.value = '0';

        //Control the "Play" scenario
        let scanTest = 1;
        if((scanRate.value<=0) || (scanRate.value===undefined))
        {
            scanTest = 1;
        }
        else
        {
			let rate = 1;
            if((playbackRate.value!==undefined) && (playbackRate.value!==""))
            {
                rate = playbackRate.value;
            }
            scanTest = (1000/scanRate.value)/rate;
        }
        
        if((isPlaying.value) && (tickCount >= scanTest))
        {
            tickCount = 0;
            if(dataProcessed)
            {
                Increment(1,1);
            } 
        }
        if(isPlaying.value) tickCount++;
        tcount.value = tickCount;
        
        dp.value = dataProcessed;
        if (dvrfp) 
        {
            dvrfp = false;
            startdatetime.value = document.getElementById("settime").value;
            let dt = startdatetime.value;
            dt = dt.replace("T"," ");
            dt = dt.replace("/","-");
            dt = dt.replace("/","-");
            startdatetime.value = dt;
            document.getElementById("settime").value = startdatetime.value;
            ineedreload.value = "1";
            tagcount.value = "loading...";
            enddatetime.value = "loading...";
            let url = datasource.value + "?dropletid=" + dropletid.value + "&taglist=" + tagList.value + "&focus=" + startdatetime.value

            if((url !== '') && (datasource.value !== "") && (datasource.value !== undefined)) {
                fetch(url, {
                
                }).then((res) => res.json()).then((response) => {
                    let data = response;
                    console.log("DATA",data);
                    dataProcessed = undefined;
                    
                    ProcessData(data);
                }).catch((error) => {
                    console.log("DVR FETCH ERROR " + error);
                });
            } else {
                console.log("DVR NO URL SPECIFIED");
            }
        }
        ProcessData();

    }
    
    function ProcessData(data) {
        if((data!==undefined))
        {
            try {
                tempo = data;
                result.value = {}; 
                index = 0;
                cue.value = tempo.cue;

                let ks = [];
                for (var k in tempo.tags) 
                {
                    ks.push(k);
                }
                tagcount.value = ks.length;

                let dt = startdatetime.value;
                
                // dt = dt.replace("T"," ");
                // dt = dt.replace("-","/");
                // dt = dt.replace("-","/");
                // startdatetime.value = dt;
                let t = new Date(dt);
                t.setSeconds(t.getSeconds() + tempo.totalspan);
                let year = t.getFullYear();
                let month = ('0' + (t.getMonth()+1)).slice(-2);
                let day = ('0' + t.getDate()).slice(-2);
                let hour = ('0' + t.getHours()).slice(-2);
                let minute = ('0' + t.getMinutes()).slice(-2);
                let second = ('0' + t.getSeconds()).slice(-2);
                enddatetime.value = year+"-"+month+"-"+day+" "+hour+":"+minute+":"+second;
                dataProcessed = true;
                const date1 = Date.parse(startdatetime.value);
                const date2 = Date.parse(enddatetime.value);
                const diffTime = date2 - date1;
                dataInMinutes.value = Math.ceil(diffTime / (1000 * 60));
                
            }
            catch(e){
                console.log("Bad Response from server ",e);
            }
            ineedreload.value = "0";
            
        }
        //Result TimeSlice Processing
        if((dataProcessed !== undefined))
        {
            if(lastIndex !== index)
            {
                result.value = {};
                //Create the result object using a backfill method
                for(var obj in tempo.tags)
                {
                    if(obj!==undefined)
                    {
                        var i = index;
                        var tt = 0;
                        if ((tempo.tags[obj][i]===undefined) && (lastTagValue[obj]!==undefined))
                        {
                            result.value[obj] = lastTagValue[obj];
                            tempo.tags[obj][index] = lastTagValue[obj];
                        }
                        
                        else if((tempo.tags[obj][i]===undefined) && (lastTagValue[obj]===undefined))
                        { 
                            while ((tempo.tags[obj][i]===undefined) && (i>=0) && (tt<index))
                            {
                                i--;
                                tt++;
                            }
                            result.value[obj] = tempo.tags[obj][i];
                            lastTagValue[obj] = tempo.tags[obj][i];
                            tempo.tags[obj][index] = tempo.tags[obj][i];
                        }
                        else
                        {
                            result.value[obj] = tempo.tags[obj][i];
                            lastTagValue[obj] = tempo.tags[obj][i];
                        }
                    }
                }
                lastIndex = index;
            }
        }
        virtualtime.value = vtime;
        timeslice.value = index;
        result.value.time = startdatetime.value;
        result.value.isPlaying = isPlaying.value;
        emit('new-data',result.value);
    }

    function Increment(delta,mult) {
        delta = delta*mult;
        index = index + delta;
        if(index<0)
        {
            index = 0;
            ineedreload.value = "1";
            isPlaying.value = false;   
            startstop.value = 0; 
        }
        else if(index>=tempo.totalspan)
        {
            index = tempo.totalspan;
            ineedreload.value = "1";
            isPlaying.value = false;
            startstop.value = 0;
        }
        else if(index>0)
        {
            ineedreload.value = "0";
        }
        var dt = vtime.value;
        dt = dt.replace("-","/");
        dt = dt.replace("-","/");
        var t = new Date(dt);
        t.setSeconds(t.getSeconds() + delta);
        var year = t.getFullYear();
        var month = ('0' + (t.getMonth()+1)).slice(-2);
        var day = ('0' + t.getDate()).slice(-2);
        var hour = ('0' + t.getHours()).slice(-2);
        var minute = ('0' + t.getMinutes()).slice(-2);
        var second = ('0' + t.getSeconds()).slice(-2);
        if(year!=="NaN")
        {
            vtime.value = year+"-"+month+"-"+day+" "+hour+":"+minute+":"+second;
        }
    }

</script>

<style lang="scss" scoped>
.actions{
	.play{
		color: #787878;
		border-color: #787878;
		background-color: #fff;
		width: 100%;
		height: 48px;
		line-height: 48px;
		margin-top: 20px;
	}
	.estimate{
		margin-top: 10px;
		font-size: 1rem;
		line-height: 1.2rem;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		justify-content: center;
		text-transform: capitalize;
		height: 72px;
		padding: 0 1.1rem;
		font-weight: 400;
	}
}
.request{
	background-color: #f8f8f8;
	margin: 20px;
	padding: 10px;
	border-radius: 10px;
    @media screen and (max-width: 820px) {
      margin: 20px 0;
    }
	.start_end{
		>div{
			width: 100%;
			display: flex;
			padding: 0 10px;
			margin-bottom: 10px;
			box-sizing: border-box;
			p,span{
				height: 32px;
				line-height: 32px;
			}
			span{
				color: #252525;
				width: 2.5rem;
			}
			p{
				margin: 0;
				color: #444;
				text-align: center;
				background-color: #2525251A;
				border-radius: 6px;
				flex-grow: 1;
			}
		}
	}
	>p{
		margin: 0px;
	}
	.load_time{
		display: flex;
		flex-direction: row;
		flex-flow: wrap;
		padding: 10px 10px 40px;
		.button{
			color: #787878;
			border-color: #787878;
			background-color: #fff;
			width: 100%;
			height: 40px;
			line-height: 40px;
			margin-top: 10px;
		}
		>p{
			width: 100%;
			flex-grow: 1;
			flex-shrink: 0;
		}
		::-webkit-input-placeholder {
			text-align: center;
		}
		:-moz-placeholder { /* Firefox 18- */
			text-align: center;  
		}
		::-moz-placeholder {  /* Firefox 19+ */
			text-align: center;  
		}
		:-ms-input-placeholder {  
			text-align: center; 
		}
		span{
			display: inline-block;
			width: 0.8rem;
			text-align: center;
			height: 1.68rem;
			line-height: 1.68rem;
			font-size: 1rem;
		}
		input:nth-child(2){
			width: 100%;
		}
		input[type=number] {
			-moz-appearance:textfield; /* Firefox */
		}
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
		}
	}
}
.speed{
	p{
		padding-left: 20px;
		color: #252525;
	}
	.change_speed{
		display: flex;
		justify-content: center;
		div{
			height: 52px;
			width: 52px;
			background-color: #787878;
			border-radius: 10px;
		}
		.more,.less{
			background-image: url(../../../public/img/boeing/ma/ff.svg);
			background-position: center;
			background-repeat: no-repeat;
			background-size: 24px;
			margin: 0 10px;
			cursor: pointer;
		}
		.less{
			transform: rotate(180deg);
		}
		.the_speed{
			list-style-type: none;
			color: #fff;
			height: 52px;
			line-height: 52px;
			border-radius: 26px;
			text-align: center;
			font-size: 1rem;
			font-weight: 400;
		}
	}
}
.dvrDisp {
  position: absolute;
  top: 74px;
  bottom: 0px;
  right:0px;
  width: 100%;
  @media screen and (max-width: 820px) {
    position: initial;
  }
}
.player {
    position:relative;
	// height: 100%;
	width: 100%;
}
.startdate {
  position:absolute;
	color: #fff;
  left: 20px;
  top: 38px;
  font-size: 16px;
}
.selector {
  position:absolute;
  left:0;
  bottom:0;
  height:40%;
  width:100%;
}
.selector:after{
	content: 'DVR';
	position: absolute;
	color: #787878;
	font-size: 12px;
	right: 13px;
	bottom: 8px;
	font-weight: 500;
}
.enddate {
  position:absolute;
	color: #fff;
  right: 53px;
  top: 200px;
  font-size: 10px;
}
.tagcount {
  position:absolute;
	color: #fff;
  left: 179px;
  top: 18px;
  font-size: 10px;
}
.playbackrate {
    position:absolute;
    left:0%;
    top:50%;
    font-size:100%;//40px;
}
.datepicker {
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  position: relative;
  border: none;
  border-radius: 8px;
  outline: 0;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  margin: 0;
  font-size: 1rem;
  text-align: center;
  color: #252525;
  background-color: #2525251A;
  width: 100%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  @media screen and (max-width: 640px) {
    margin-right: 0px;
  }
}

.datepicker::-webkit-calendar-picker-indicator {
  background: transparent;
  color: transparent;
  cursor: pointer;
  height: auto;
  width: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-family: 'Inter', sans-serif;
}
@keyframes blink {
    0% {
           color: rgb(35, 158, 1)
    }
    50% {
           color: rgba(35, 158, 1,0.2)
    }
    100% {
           color: rgb(35, 158, 1)
    }
}
@-webkit-keyframes blink {
    0% {
           color: rgb(35, 158, 1)
    }
    50% {
           color: rgba(35, 158, 1,0.2)
    }
    100% {
           color: rgb(35, 158, 1)
    }
}
 .playing {
    position:absolute;
    top:13%;
    left:73%;
    text-align:left;
    font-size: 150%;
    font-weight: bold;
    color:green;
    // -moz-transition:all 2s ease-in-out;
    // -webkit-transition:all 2s ease-in-out;
    // -o-transition:all 2s ease-in-out;
    // -ms-transition:all 2s ease-in-out;
    // transition:all 2s ease-in-out;
    // -moz-animation:blink normal 2s infinite ease-in-out;
    // /* Firefox */
    // -webkit-animation:blink normal 2s infinite ease-in-out;
    // /* Webkit */
    // -ms-animation:blink normal 2s infinite ease-in-out;
    // /* IE */
    // animation:blink normal 2s infinite ease-in-out;
    // /* Opera */
}
.box-year {
    position:absolute;
    left:0%;
    top:0%;
    width: 20%;
    height: 16%;
    z-index:1;
    border-radius:10px;
}
.box-year-active {
    position:absolute;
    left:0%;
    top:0%;
    width: 20%;
    height: 16%;
    z-index:1;
    border-radius:10px;
    background-color: rgba(44, 237, 44, 0.25);
}
.box-month {
    position:absolute;
    left:24%;
    top:0%;
    width: 10%;
    height: 16%;
    z-index:1;
    border-radius:10px;
}
.box-month-active {
    position:absolute;
    left:24%;
    top:0%;
    width: 10%;
    height: 16%;
    z-index:1;
    border-radius:10px;
    background-color: rgba(44, 237, 44, 0.25);
}
.box-day {
    position:absolute;
    left:38%;
    top:0%;
    width: 11%;
    height: 16%;
    z-index:1;
    border-radius:10px;
}
.box-day-active {
    position:absolute;
    left:38%;
    top:0%;
    width: 11%;
    height: 16%;
    z-index:1;
    border-radius:10px;
    background-color: rgba(44, 237, 44, 0.25);
}
.box-hour {
    position:absolute;
    left:52%;
    top:0%;
    width: 12%;
    height: 16%; 
    z-index:1;
    border-radius:10px;
}
.box-hour-active {
    position:absolute;
    left:52%;
    top:0%;
    width: 12%;
    height: 16%; 
    z-index:1;
    border-radius:10px;
    background-color: rgba(44, 237, 44, 0.25);
}
.box-minute {
    position:absolute;
    left:66%;
    top:0%;
    width: 12%;
    height: 16%;
    z-index:1;
    border-radius:10px;
}
.box-minute-active {
    position:absolute;
    left:66%;
    top:0%;
    width: 12%;
    height: 16%;
    z-index:1;
    border-radius:10px;
    background-color: rgba(44, 237, 44, 0.25);
}
.box-second {
    position:absolute;
    left:81%;
    top:0%;
    width: 11%;
    height: 16%;
    z-index:1;
    border-radius:10px;
    // background-color: rgba(237, 44, 150, 0.25);
}
.box-second-active {
    position:absolute;
    left:81%;
    top:0%;
    width: 11%;
    height: 16%;
    z-index:1;
    border-radius:10px;
    background-color: rgba(44, 237, 44, 0.25);
}
.dec,.inc,.load,.pause{
	background-color: #787878;
	display: inline-block;
  position: absolute;
  height: 54px;
	width: 54px;
	line-height: 53px;
	border-radius: 26px;
	text-align: center;
	color: #fff;
	// bottom: -26px;
	font-size: 22px;
	cursor: pointer;
	box-sizing: border-box;
}
.dec {
	left: 18px;
}
.inc{
	left: 192px;
}
// .pause{
// 	left: 256px;
// 	// border: 4px solid #f44336;
// 		background-position: center;
// 		background-image: url(../../../public/img/boeing/ma/=.svg);
// 		background-repeat: no-repeat;
// }
.load{
	left: 320px;
	border: 4px solid #f44336;
		background-position: center;
		background-image: url(../../../public/img/boeing/ma/ellipse.svg);
		background-repeat: no-repeat;
}
// .inc {
//     position:absolute;
//     top:0%;
//     left:90%;
//     height:20%;
//     width:20%;
// }
// .load {
//     position:absolute;
//     top:20%;
//     left:50%;
//     height:30%;
//     width:30%;
// }

.cue {
    position:absolute;
    top:25%;
    left:74%;
    height:20%;
    width:20%;
}

.datepicker {
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  position: relative;
  border: none;
  border-radius: 8px;
  outline: 0;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  margin: 0;
  font-size: 1rem;
  text-align: center;
  color: #252525;
  background-color: #2525251A;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  @media screen and (max-width: 640px) {
    margin-right: 0px;
  }
}

.datepicker::-webkit-calendar-picker-indicator {
  background: transparent;
  color: transparent;
  cursor: pointer;
  height: auto;
  width: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-family: 'Inter', sans-serif;
}

input[type="date"]::-webkit-datetime-edit-text,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field,
input[type="date"]::-webkit-datetime-edit-year-field {
  font-family: 'Inter', sans-serif;
}
</style>