<template>
  <div v-if="controls===true">
    <div>Live: {{ isActive }}</div>
    <div>
      <button @click="pause">
        pause
      </button>
      <button @click="resume">
        resume
      </button>
    </div>
  </div>
</template>

<script setup>
    import { toRefs } from 'vue'
    import { useTimeoutPoll } from '@vueuse/core'
    const props = defineProps({ scanrate: Number, controls: Boolean });
    const { src, scanrate } = toRefs(props);
    const emit = defineEmits(['on-data']);
    async function fetchData() { emit('on-data',""); }
    const { isActive, pause, resume } = useTimeoutPoll(fetchData, scanrate.value, {immediate:true});

</script>
